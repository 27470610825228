import React from "react"

const UNosaci = () => {
  return (
    <div className="box" style={{ width: "100%" }}>
      <div className="column">
        <p>Proizvodna dužina: 6 m, 12 m. Po zahtevu moguće sečenje na meru.</p>
      </div>
      <div className="table-scroll">
        <table class="table is-bordered is-hoverable">
          <tbody>
            <tr className="has-background-link has-text-white">
              <td rowSpan={2}>
                <br />
                Oznaka UPN
              </td>
              <td colSpan={6}>Mere (mm)</td>
              <td rowSpan={2}>
                <br />
                Masa (kg)
              </td>
            </tr>
            <tr>
              <td>h</td>
              <td>b</td>
              <td>s</td>
              <td>t</td>
              <td>r</td>
              <td>r1</td>
            </tr>
            <tr>
              <td>80</td>
              <td>80</td>
              <td>42,00</td>
              <td>3,9</td>
              <td>5,9</td>
              <td>3,9</td>
              <td>2,3</td>
              <td>5,94</td>
            </tr>
            <tr>
              <td>100</td>
              <td>100</td>
              <td>50,00</td>
              <td>4,5</td>
              <td>6,8</td>
              <td>4,5</td>
              <td>2,7</td>
              <td>8,34</td>
            </tr>
            <tr>
              <td>120</td>
              <td>120</td>
              <td>58,00</td>
              <td>5,1</td>
              <td>7,7</td>
              <td>5,1</td>
              <td>3,1</td>
              <td>11,10</td>
            </tr>
            <tr>
              <td>140</td>
              <td>140</td>
              <td>66,00</td>
              <td>5,7</td>
              <td>8,6</td>
              <td>5,7</td>
              <td>3,4</td>
              <td>14,30</td>
            </tr>
            <tr>
              <td>160</td>
              <td>160</td>
              <td>74,00</td>
              <td>6,3</td>
              <td>9,5</td>
              <td>6,3</td>
              <td>3,8</td>
              <td>17,90</td>
            </tr>
            <tr>
              <td>180</td>
              <td>180</td>
              <td>82,00</td>
              <td>6,9</td>
              <td>10,4</td>
              <td>6,9</td>
              <td>4,1</td>
              <td>21,90</td>
            </tr>
            <tr>
              <td>200</td>
              <td>200</td>
              <td>90,00</td>
              <td>7,5</td>
              <td>11,3</td>
              <td>7,5</td>
              <td>4,5</td>
              <td>26,20</td>
            </tr>
            <tr>
              <td>220</td>
              <td>220</td>
              <td>98,00</td>
              <td>8,1</td>
              <td>12,2</td>
              <td>8,1</td>
              <td>4,9</td>
              <td>31,10</td>
            </tr>
            <tr>
              <td>240</td>
              <td>240</td>
              <td>106,00</td>
              <td>8,7</td>
              <td>13,1</td>
              <td>8,7</td>
              <td>5,2</td>
              <td>36,20</td>
            </tr>
            <tr>
              <td>260</td>
              <td>260</td>
              <td>113,00</td>
              <td>9,4</td>
              <td>14,1</td>
              <td>9,4</td>
              <td>5,6</td>
              <td>41,90</td>
            </tr>
            <tr>
              <td>280</td>
              <td>280</td>
              <td>119,00</td>
              <td>10,1</td>
              <td>15,2</td>
              <td>10,1</td>
              <td>6,1</td>
              <td>47,90</td>
            </tr>
            <tr>
              <td>300</td>
              <td>300</td>
              <td>125,00</td>
              <td>10,8</td>
              <td>16,2</td>
              <td>10,8</td>
              <td>6,5</td>
              <td>54,20</td>
            </tr>
            <tr>
              <td>320</td>
              <td>320</td>
              <td>131,00</td>
              <td>11,5</td>
              <td>17,3</td>
              <td>11,5</td>
              <td>6,9</td>
              <td>61,00</td>
            </tr>
            <tr>
              <td>340</td>
              <td>340</td>
              <td>137,00</td>
              <td>12,2</td>
              <td>18,3</td>
              <td>12,2</td>
              <td>7,3</td>
              <td>68,00</td>
            </tr>
            <tr>
              <td>360</td>
              <td>360</td>
              <td>143,00</td>
              <td>13,0</td>
              <td>19,5</td>
              <td>13,0</td>
              <td>7,8</td>
              <td>76,10</td>
            </tr>
            <tr>
              <td>400</td>
              <td>400</td>
              <td>155,00</td>
              <td>14,4</td>
              <td>21,6</td>
              <td>14,4</td>
              <td>8,6</td>
              <td>92,40</td>
            </tr>
            <tr>
              <td>450</td>
              <td>450</td>
              <td>170,00</td>
              <td>16,2</td>
              <td>24,3</td>
              <td>16,2</td>
              <td>9,7</td>
              <td>115,00</td>
            </tr>
            <tr>
              <td>500</td>
              <td>500</td>
              <td>185,00</td>
              <td>18,0</td>
              <td>27,0</td>
              <td>18,0</td>
              <td>10,80</td>
              <td>141,00</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default UNosaci
